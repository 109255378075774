import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {StatusType} from '@shared/components/status/status.types';

import {DisbursementStatus} from '../../../modules/credit-disbursement/entity';

@Component({
  selector: 'hea-disbursements-status',
  template: `
    <hea-status [type]="disbursementsStatusTypeMap[status]"
                [tooltipMessage]="tooltipMessage">{{disbursementsStatusNameMap[status]}}</hea-status>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisbursementStatusComponent {
  @Input() status: DisbursementStatus
  @Input() truncate = 36;
  @Input() tooltipMessage: string;

  readonly disbursementsStatusTypeMap: Record<DisbursementStatus, StatusType> = {
    [DisbursementStatus.checkCashed]: 'info',
    [DisbursementStatus.checkIssued]: 'info',
    [DisbursementStatus.checkRequested]: 'info',
    [DisbursementStatus.redeemed]: 'success',
    [DisbursementStatus.unDelivered]: 'error',
    [DisbursementStatus.pending]: 'warn',
    [DisbursementStatus.success]: 'success',
    [DisbursementStatus.failed]: 'error',
    [DisbursementStatus.checkExpired]: 'error',
    [DisbursementStatus.canceled]: 'error',
    [DisbursementStatus.unknown]: 'info',
    [DisbursementStatus.expired]: 'error',
    [DisbursementStatus.terminated]: 'error',
    [DisbursementStatus.returned]: 'warn',
  }

  readonly disbursementsStatusNameMap: Record<DisbursementStatus, string> = {
    [DisbursementStatus.checkCashed]: 'Check Cashed',
    [DisbursementStatus.checkIssued]: 'Check Issued',
    [DisbursementStatus.checkExpired]: 'Check Expired',
    [DisbursementStatus.checkRequested]: 'Check Requested',
    [DisbursementStatus.redeemed]: 'Redeemed',
    [DisbursementStatus.unDelivered]: 'Un-delivered',
    [DisbursementStatus.pending]: 'Pending',
    [DisbursementStatus.success]: 'Success',
    [DisbursementStatus.failed]: 'Failed',
    [DisbursementStatus.canceled]: 'Canceled',
    [DisbursementStatus.unknown]: 'Unknown',
    [DisbursementStatus.expired]: 'Expired',
    [DisbursementStatus.terminated]: 'Terminated',
    [DisbursementStatus.returned]: 'Returned',
  }
}


