import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorTypes} from '@core/common';

@Injectable({providedIn: 'root'})
export class ErrorMessageService {

    static getErrorWithMessage(error: HttpErrorResponse | string): HttpErrorResponse | string {

        if (typeof error === 'string' || error instanceof String) {
            return ErrorMessageService.getHttpErrorMessageByCode(error as any);
        }

        if ((!error.hasOwnProperty('error') || !error.error) || !error.error.hasOwnProperty('code')) {
            return error;
        }

        error.error.message = ErrorMessageService.getHttpErrorMessageByCode(error.error.code);
        return error;
    }

    static getHttpErrorMessageByCode(code: ErrorTypes): string {
        switch (code) {
            case ErrorTypes.unknown : {
                return 'Something wrong';
            }
            case ErrorTypes.disbursementFileDuplicate : {
                return 'Upload Failed; The Disbursement Data file with this name has already been uploaded.';
            }
            case ErrorTypes.demographicFileDuplicate : {
                return 'Upload Failed; This Demographic File has already been uploaded.';
            }
            case ErrorTypes.disbursementDataDuplicate : {
                return 'The Disbursement File contains the duplicated data';
            }
            case ErrorTypes.demographicDataImportFailed : {
                return 'Import Failed';
            }
            case ErrorTypes.fileInvalid : {
                return 'Upload Failed; Invalid File Format';
            }
            case ErrorTypes.disbursementFileNameInvalid: {
                return 'Upload Failed; Invalid File Name Format';
            }
            case ErrorTypes.disbursementFileHeaderInvalid: {
                return 'Upload Failed; There is an invalid header in the File you are trying to upload.';
            }
            case ErrorTypes.disbursementFileInvalidFormat: {
                return 'Invalid File Format';
            }
            case ErrorTypes.disbursementFileInvalidDataFormat: {
                return 'Invalid Data Format';
            }
            case ErrorTypes.disbursementFileRequiredDataMissing: {
                return 'Upload Failed; Required Data is Missing';
            }
            case ErrorTypes.disbursementTransactionNotFound: {
                return 'Disbursement Transaction Not Found';
            }
            case ErrorTypes.disbursementAmountExceedsBalance: {
                return 'Not enough funds on your escrow account.';
            }
            case ErrorTypes.disbursementToPaperCheck: {
                return 'An error has occurred preventing the conversion of this disbursement to paper check.';
            }
            case ErrorTypes.studentWithTheSameEmailAlreadyExists: {
                return 'Student With The Same Email Already Exists';
            }
            case ErrorTypes.demographicDataStudentIdRequired: {
                return 'Student ID Required';
            }
            case ErrorTypes.demographicDataStudentIdLengthInvalid: {
                return 'Student ID should be between 1-150 characters long';
            }
            case ErrorTypes.demographicDataFirstNameRequired: {
                return 'First Name Required';
            }
            case ErrorTypes.demographicDataFirstNameLengthInvalid: {
                return 'First Name should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataLastNameRequired: {
                return 'Last Name Required';
            }
            case ErrorTypes.demographicDataLastNameLengthInvalid: {
                return 'Last Name should be be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataAmountRequired: {
                return 'Amount Required';
            }
            // case ErrorTypes.demographicDataAmountShouldBePositive: {
            //     return 'Amount should be Positive';
            // }
            case ErrorTypes.demographicDataEmailRequired: {
                return 'Email Required';
            }
            case ErrorTypes.demographicDataEmailLengthInvalid: {
                return 'Email should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataEmailFormatInvalid: {
                return 'Invalid Email';
            }
            // case ErrorTypes.demographicDataPhoneRequired: {
            //     return 'Phone Required';
            // }
            case ErrorTypes.demographicDataPhoneLengthInvalid: {
                return 'Phone should be 10 characters long';
            }
            case ErrorTypes.demographicDataPhoneFormatInvalid: {
                return 'Invalid Phone';
            }
            case ErrorTypes.demographicDataMobileLengthInvalid: {
                return 'Phone should be 10 characters long';
            }
            case ErrorTypes.demographicDataMobileFormatInvalid: {
                return 'Invalid Phone';
            }
            // case ErrorTypes.demographicDataPhone2EqualsPhone1: {
            //   return 'Phone 2 should not be Phone 1';
            // }
            case ErrorTypes.demographicDataAddressLine1Required: {
                return 'Address Line 1 Required';
            }
            case ErrorTypes.demographicDataAddressLine1LengthInvalid: {
                return 'Address Line 1 should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataAddressLine2LengthInvalid: {
                return 'Address Line 2 should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataCountryRequired: {
                return 'US Address Required';
            }
            case ErrorTypes.demographicDataCountryLengthInvalid: {
                return 'US Address Required';
            }
            case ErrorTypes.demographicDataCountryNotSupported: {
                return 'US Address Required';
            }
            case ErrorTypes.demographicDataCityRequired: {
                return 'City Required';
            }
            case ErrorTypes.demographicDataCityLengthInvalid: {
                return 'City should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataStateRequired: {
                return 'State Required';
            }
            case ErrorTypes.demographicDataStateLengthInvalid: {
                return 'State should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataZipCodeRequired: {
                return 'Zip Code Required';
            }
            case ErrorTypes.demographicDataZipCodeLengthInvalid: {
                return 'Zip code should be 5 or 9 characters long';
            }
            case ErrorTypes.demographicDataTermCodeRequired: {
                return 'Term Code Required';
            }
            case ErrorTypes.demographicDataTermCodeLengthInvalid: {
                return 'Term Code should be between 1-150 characters long';
            }
            case ErrorTypes.demographicDataTermDescriptionLengthInvalid: {
                return 'Term Description should be between 2-150 characters long';
            }
            case ErrorTypes.demographicDataNoteLengthInvalid: {
                return 'Note should be between 2-150 characters long';
            }
            case ErrorTypes.disbursementCheckIssuingForbidden: {
                return 'An error has occurred preventing the conversion of this disbursement to paper check.';
            }
            // case ErrorTypes.demographicDataAmountLimitExceeded: {
            //   return 'Amount limit exceeded.';
            // }
            case ErrorTypes.commonFileSizeLimitExceeded: {
                return 'File size limit exceeded.';
            }

            default: {
                if (code) {
                    return 'Something wrong:' + ' ' + (code as any)?.split(/([A-Z]?[^A-Z]*)/g).join(' ');
                } else {
                    return 'Something wrong';
                }
            }
        }
    }

    static getHttpErrorMessagesByCodes(codes: ErrorTypes[]): string[] {
        return codes?.map((code) => ErrorMessageService.getHttpErrorMessageByCode(code));
    }

}

