import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hea-view-template-date',
  templateUrl: './view-template-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTemplateDateComponent {
  @Input() value: string;
  @Input() color: string;
  @Input() size: string;
  @Input() format = 'MMM d, y';
}
