import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {StatusType} from '@shared/components/status/status.types';

import {FilesStatus} from '../../../modules/disbursement-import/entity';

@Component({
  selector: 'hea-disbursement-file-status',
  template: `
    <div class="flex items-center">
      <mat-icon class="mr-2 size-sm cursor-pointer" svgIcon="information-outline"
                [matTooltip]="toolTipMap[status]"></mat-icon>
      <hea-status class="cursor-pointer" [type]="statusTypeMap[status]">{{statusNameMap[status]}}</hea-status>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisbursementFileStatusComponent {
  @Input() status: FilesStatus
  @Input() truncate = 36;
  @Input() tooltip: string;

  readonly statusTypeMap: Record<FilesStatus, StatusType> = {
    [FilesStatus.successful]: 'success',
    [FilesStatus.failed]: 'error',
    [FilesStatus.fixRequired]: 'warn',
    [FilesStatus.importStarted]: 'success',
    [FilesStatus.fixed]: 'success',
    [FilesStatus.initiated]: 'success',
  }

  readonly statusNameMap: Record<FilesStatus, string> = {
    [FilesStatus.successful]: 'Successful',
    [FilesStatus.failed]: 'Failed',
    [FilesStatus.fixRequired]: 'Fix Required',
    [FilesStatus.importStarted]: 'Import Started',
    [FilesStatus.fixed]: 'Fixed',
    [FilesStatus.initiated]: 'Initiated & Locked',
  }

  readonly toolTipMap: Record<FilesStatus, string> = {
    [FilesStatus.successful]: 'The file has been successfully uploaded with all mandatory information' +
    ' required. You can now initiate the disbursement.',
    [FilesStatus.failed]: `There is an error in the file that prevented uploading. Please, review, make corrections and repeat upload`,
    [FilesStatus.fixRequired]: `One or more mandatory information are missing. Click the Edit icon and enter all required information`,
    [FilesStatus.importStarted]: 'Import Started',
    [FilesStatus.fixed]: 'All missing mandatory information has been added. You can now initiate the disbursement.',
    [FilesStatus.initiated]: `All student disbursements included in this file have been initiated. Disbursement progress can be monitored on the Credit Disbursement page.
`,
  }
}


