export interface NegotiateRes {
  accessToken: string;
  url: string;
}

export interface NotificationMessage {
  CreatedOnUtc: string;
  Event: NotificationEvent;
  EventDescription: string;
}

export enum NotificationEvent {
  recipientEntryUpdated = 'recipient.entry.updated',
  disbursementEntryValid = 'disbursement.entry.valid',
  disbursementEntryInvalid = 'disbursement.entry.invalid',
  disbursementBatchFileUploadStarted = 'disbursement.batch.file.upload.started',
  disbursementBatchVerificationCompleted = 'disbursement.batch.verification.completed',
  recipientBatchFileUploadStarted = 'recipient.batch.file.upload.started',
  recipientBatchFileUploadCompleted = 'recipient.batch.file.upload.completed',
  disbursementRequestCanceled = 'transaction.recipient.payment.disbursement.request.canceled',
  studentPayment = 'payment.order.updated'
}

export interface AddToGroupRes {
  Action: string;
  ConnectionId: string;
  Endpoints: string;
  GroupName: string;
  UserId: string;
}
