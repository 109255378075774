import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';

import {ContactUsComponent} from './contact-us.component';

@NgModule({
  declarations: [ContactUsComponent],
  imports: [
    SharedModule,
  ]
})
export class ContactUsModule {

}
