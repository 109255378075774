export enum PaymentStatus {
  declined = 'declined',
  pending = 'pending',
  success = 'success',
  scheduled = 'scheduled'
}

export enum PaymentMethod {
  'Card' = 'Card',
  'Bank Draft' = 'Bank Draft'
}

export enum PaymentType {
  'Payment Plan' = 'Payment Plan',
  'Single Pay' = 'Single Pay',
}

export enum PaymentConfigStatus {
  active = 'active',
  inactive = 'inactive',
}
