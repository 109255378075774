<ng-container *ngIf="!strong">
    <div *ngIf="value && !isDecimal" class="fs-{{ size }} color-{{ color }}">
        {{ value | currency : '' : '' : '1.0-0' }}
    </div>

    <div *ngIf="!value" class="fs-{{ size }} color-{{ color }}">0</div>

    <div *ngIf="value && isDecimal" class="color-{{ color }} fs-{{ size }}">
        {{ value | currency : '' : '' : '1.0-0'
        }}<small>.{{ value | heaDecimal }}</small>
    </div>
</ng-container>

<ng-container *ngIf="strong">
    <div *ngIf="value" class="color-{{ color }} fs-{{ size }}">
        <strong>{{ value | currency : '' : '' : '1.0-0' }}</strong>
    </div>
    <div *ngIf="!value" class="color-{{ color }} fs-{{ size }}">
        <strong>0</strong>
    </div>
</ng-container>
