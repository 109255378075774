import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cardNumber',
})
export class CardNumberPipe implements PipeTransform {
  transform(value: string): string {
    return value?.split('')
      .reverse()
      .map((value, index) =>
        `${index > 3 ? '*': value}${index>0 && index%4===0?' ':''}`
      )
      .reverse()
      .join('')
  }
}
