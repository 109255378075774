import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseEntity, ListResponse, Pagination} from '@core/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, finalize, Observable, Subject, switchMap, tap} from 'rxjs';

type DataFetcherFn = (pagination?: Pagination) => Observable<ListResponse | any[]>;

@UntilDestroy()
@Component({
  selector: 'hea-infinite-scroll-selector',
  templateUrl: './infinite-scroll-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfiniteScrollSelectorComponent),
      multi: true,
    }
  ]
})
export class InfiniteScrollSelectorComponent<T = unknown, ID = unknown> implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() icon: string;
  @Input() selectedOptions: BaseEntity<ID>[] = [];
  @Input() formControl: FormControl;
  @Input() placeholder: string;
  @Input() dataFetcherFn: DataFetcherFn;
  @Input() pageSize = 100;
  @Input() multiple = false;
  @Output() selectionChange = new EventEmitter<void>();
  fetchedCount = 0;
  total = 0;
  options$: Observable<any[]>;
  isRequestPending = false;

  private alreadyFetchedOptionsSet = new Set<ID>();

  get value(): T {
    return this._value;
  }

  set value(val: T) {
    this._value = val;
    if (this.onChange) {
      this.onChange(val);
    }
    if (this.onTouch) {
      this.onTouch(val);
    }
  }

  onChange: (_: any) => {};
  onTouch: (_: any) => {};

  private pagination$ = new Subject<Pagination>();

  private optionsSubject = new BehaviorSubject<BaseEntity<ID>[]>([]);

  private currentPage = 0;

  private _value: T;

  constructor() {
    this.options$ = this.optionsSubject.asObservable();
  }

  ngOnInit(): void {
    this.checkSelectedOptions();
    this.subscribeToPaginationChanges();
    this.updatePagination();
  }

  getNextBatch(): void {
    this.currentPage += 1;
    this.updatePagination();
  }

  writeValue(obj: T): void {
    this.value = obj;
  }

  registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouch = fn;
  }

  private updatePagination(): void {
    if (this.total >= this.fetchedCount) {
      this.pagination$.next({
        page: this.currentPage,
        size: this.pageSize,
      });
    }
  }

  private subscribeToPaginationChanges(): void {
    this.pagination$.asObservable().pipe(
      tap(() => this.toggleIsRequestPendingState(true)),
      switchMap((pagination) => this.dataFetcherFn(pagination ? pagination : null)),
      tap((response) => {
        if (Array.isArray(response)) {
          this.optionsSubject.next(response);
        } else {
          this.fetchedCount += response?.data?.length || 0;
          this.total = response?.totalElements || 0;
          if (response?.data?.length) {
            this.optionsSubject.next([
              ...response.data.filter(val => !this.alreadyFetchedOptionsSet.has(val.id)),
              ...(this.optionsSubject?.value || null)
            ]);
          }
        }

      }),
      tap(() => this.toggleIsRequestPendingState()),
      finalize(() => this.toggleIsRequestPendingState()),
      untilDestroyed(this),
    ).subscribe();
  }

  private toggleIsRequestPendingState(isPending = false): void {
    this.isRequestPending = isPending;
  }

  private checkSelectedOptions(): void {
    if (this.selectedOptions?.length) {
      this.optionsSubject.next(this.selectedOptions);
      this.selectedOptions.forEach((value) => this.alreadyFetchedOptionsSet.add(value.id));
    }
  }
}
