import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ListResponse, Pagination} from '@core/common';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {Disbursement, DisbursementsListFilter, RedeemTotalsTypes} from './disbursements.types';

@Injectable({providedIn: 'root'})
export class DisbursementsApiService {

  constructor(private httpClient: HttpClient) {
  }

  getDisbursements(pagination: Pagination, filter: DisbursementsListFilter): Observable<ListResponse<Disbursement>> {
    const params: any = {
      page: pagination.page,
      size: pagination.size,
      ...filter
    };

    return this.httpClient.get<ListResponse<Disbursement>>(`${environment.baseURL}/api/transactions`, {params});
  }

  cancelDisbursement(transactionId: number): Observable<any> {
    return this.httpClient.put<any>(`${environment.baseURL}/api/transactions/${transactionId}/cancel`, {});
  }

  convertDisbursement(transactionId: number): Observable<any> {
    return this.httpClient.post(`${environment.baseURL}/api/disbursements/${transactionId}/check`, {});
  }

  getRedeemTotals(filter: DisbursementsListFilter): Observable<RedeemTotalsTypes> {
    const params: any = {
      ...filter
    }
    return this.httpClient.get<RedeemTotalsTypes>(`${environment.baseURL}/api/disbursements/report`, {params});
  }
}
