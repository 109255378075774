import {NgModule} from '@angular/core';
import {DecimalPipe} from '@shared/pipes/decimal.pipe';
import {SafePipe} from '@shared/pipes/safe.pipe';

import {FullNamePipe} from './full-name.pipe';
import {HttpErrorMessagePipe} from './http-error-message.pipe';
import { PaymentTypePipe } from '@shared/pipes/payment-type.pipe';
import { PaymentMethodPipe } from '@shared/pipes/payment-method.pipe';
import { CardNumberPipe } from '@shared/pipes/card-number.pipe';
import {TruncatePipe} from './truncate.pipe';
import {ValueOrDefaultPipe} from './value-or-default.pipe';
import {AssetsSourcePipe} from './asset-source.pipe';

const modules = [
  AssetsSourcePipe,
  FullNamePipe,
  HttpErrorMessagePipe,
  TruncatePipe,
  ValueOrDefaultPipe,
  SafePipe,
  TruncatePipe,
  PaymentTypePipe,
  PaymentMethodPipe,
  CardNumberPipe,
  DecimalPipe
];

@NgModule({
  declarations: [...modules],
  exports: [...modules],
  providers: [...modules]
})
export class PipesModule {
}
