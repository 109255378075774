import {ErrorTypes} from '@core/common';

export interface Student {
  address: Address;
  amount: number;
  email: string;
  errors: ErrorTypes[];
  fileId: number;
  firstName: string;
  id: number;
  lastName: string;
  note: string;
  phone: string;
  status: StudentStatus;
  studentId: string;
  termCode: string;
  termDescription: string;

  // Internal
  errorsMap?: Partial<Record<ErrorTypes, string>>;
}

export enum StudentStatus {
  invalid = 'INVALID',
  success = 'SUCCESS',
}

export interface StudentUpdateReq {
  id?: number;
  email?: string;
  phone?: string;
  phone2?: string;
  address?: Address;
}

export interface StudentListFilter {
  search: string;
  status: StudentStatus;
}

export interface UploadedFile {
  fileId: number;
  filename: string;
  created: string;
  reason: string;
  status: FilesStatus;
  invalidRecordsCount?: number;
  recordsCount?: number;
  totalAmount?: number;
}

export enum FilesStatus {
  importStarted = 'IMPORT_STARTED',
  failed = 'FAILED',
  fixRequired = 'FIX_REQUIRED',
  successful = 'SUCCESSFUL',
  fixed = 'FIXED',
  initiated = 'INITIATED',
}

export interface UploadedFilesFilter {
  search?: string;
  status: FilesStatus;
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country?: string;
}
