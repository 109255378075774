export enum ErrorTypes {
    unknown = 'error.unknown',
    fileInvalid = 'error.demographicData.fileInvalid',
    disbursementFileDuplicate = 'error.disbursementFile.duplicate',
    demographicFileDuplicate = 'error.demographicFile.duplicate',
    disbursementDataDuplicate = 'error.disbursementData.duplicate',
    disbursementFileNameInvalid = 'error.disbursementFile.nameInvalid',
    disbursementFileHeaderInvalid = 'error.disbursementFile.headerInvalid',
    disbursementFileInvalidFormat = 'error.disbursementFile.invalidFormat',
    disbursementFileInvalidDataFormat = 'error.disbursementFile.dataFormatInvalid',
    disbursementFileRequiredDataMissing = 'error.disbursementFile.requiredDataMissing',
    demographicDataImportFailed = 'error.demographicData.importFailed',
    disbursementAmountExceedsBalance = 'error.disbursement.amountExceedsBalance',
    disbursementToPaperCheck = 'error.disbursement.toPaperCheck',
    disbursementTransactionNotFound = 'error.disbursement.berkeleyTransactionNotFound',
    studentWithTheSameEmailAlreadyExists = 'error.studentDirectory.studentWithTheSameEmailAlreadyExists',
    disbursementCheckIssuingForbidden = 'error.disbursement.checkIssuingForbidden',
    commonFileSizeLimitExceeded = 'error.common.fileSizeLimitExceeded',

    //studentId
    demographicDataStudentIdRequired = 'studentIdRequired',
    demographicDataStudentIdLengthInvalid = 'studentIdLengthInvalid',

    //firstName
    demographicDataFirstNameRequired = 'firstNameRequired',
    demographicDataFirstNameFormatInvalid = 'firstNameFormatInvalid',
    demographicDataFirstNameLengthInvalid = 'firstNameLengthInvalid',

    //lastName
    demographicDataLastNameRequired = 'lastNameRequired',
    demographicDataLastNameFormatInvalid = 'lastNameFormatInvalid',
    demographicDataLastNameLengthInvalid = 'lastNameLengthInvalid',

    //amount
    demographicDataAmountRequired = 'amountRequired',

    //old
    // demographicDataAmountShouldBePositive = 'amountShouldBePositive',
    // demographicDataAmountLimitExceeded = 'amountLimitExceeded',

    //Email
    demographicDataEmailRequired = 'emailRequired',
    demographicDataEmailFormatInvalid = 'emailFormatInvalid',
    demographicDataEmailLengthInvalid = 'emailLengthInvalid',

    //phone
    demographicDataPhoneLengthInvalid = 'phoneLengthInvalid',
    demographicDataPhoneFormatInvalid = 'phoneFormatInvalid',

    //old
    // demographicDataPhoneRequired = 'phoneRequired',
    // demographicDataPhone2LengthInvalid = 'phone2LengthInvalid',
    // demographicDataPhone2FormatInvalid = 'phone2FormatInvalid',
    // demographicDataPhone2EqualsPhone1 = 'phone2EqualsPhone1',

    //mobile
    demographicDataMobileLengthInvalid = 'mobileLengthInvalid',
    demographicDataMobileFormatInvalid = 'mobileFormatInvalid',

    //addressLine1
    demographicDataAddressLine1Required = 'addressLine1Required',
    demographicDataAddressLine1LengthInvalid = 'addressLine1LengthInvalid',

    //addressLine2
    demographicDataAddressLine2LengthInvalid = 'addressLine2LengthInvalid',

    //city
    demographicDataCityRequired = 'cityRequired',
    demographicDataCityLengthInvalid = 'cityLengthInvalid',

    //state
    demographicDataStateRequired = 'stateRequired',
    demographicDataStateLengthInvalid = 'stateLengthInvalid',

    //country
    demographicDataCountryRequired = 'countryRequired',
    demographicDataCountryLengthInvalid = 'countryLengthInvalid',
    demographicDataCountryNotSupported = 'countryNotSupported',

    //zip
    demographicDataZipCodeRequired = 'zipCodeRequired',
    demographicDataZipCodeLengthInvalid = 'zipCodeLengthInvalid',
    demographicDataZipCodeInvalidFormat = 'zipCodeIncorrectFormat',

    // termCode
    demographicDataTermCodeRequired = 'termCodeRequired',
    demographicDataTermCodeLengthInvalid = 'termCodeLengthInvalid',

    //termDescription
    demographicDataTermDescriptionLengthInvalid = 'termDescriptionLengthInvalid',

    //note
    demographicDataNoteLengthInvalid = 'noteLengthInvalid',

}
