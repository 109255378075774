import {Pagination} from './common.types';

export const INITIAL_PAGINATION_CONFIG: Pagination = {
  page: 0,
  size: 50
};

export const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];

export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZES[3];
export const DEFAULT_PAGE_SIZE_SMALL = DEFAULT_PAGE_SIZES[1];
