import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hea-view-template-string',
  templateUrl: './view-template-string.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTemplateStringComponent {
  @Input() value: any;
  @Input() color: string;
  @Input() size: string;
}
