<div class="relative">
  <mat-icon class="size-smd color-medium" svgIcon="calendar"></mat-icon>
  <input class="input"
         type="text"
         ngxDaterangepickerMd
         readonly
         [formControl]="filterPeriodCtrl"
         (rangeClicked)="onRangeClicked($event)"
         [alwaysShowCalendars]="true"
         [ranges]="ranges"
         [locale]="{format: 'MMM DD YYYY'}"
         [linkedCalendars]="true"
         [keepCalendarOpeningWithRange]="true"/>
</div>

