import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'heaDecimal',
})
export class DecimalPipe implements PipeTransform {
  transform(value: any): any {

    if (Number.isInteger(value)) {
      return '00';
    }

    const decimalStr = value.toString().split('.')[1];

    if (decimalStr.length <= 1) {
      return Number(decimalStr) + '0';
    }

    return Number(decimalStr);
  }
}
