import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ViewTemplatesModule} from '@shared/components/view-templates';
import {AgGridModule} from 'ag-grid-angular';
import {NgxMaskModule} from 'ngx-mask';
import {NgxPermissionsModule} from 'ngx-permissions';

import {ComponentsModule} from './components';
import {DirectivesModule} from './directives';
import {MaterialModule} from './material';
import {PipesModule} from './pipes';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxPermissionsModule,
  AgGridModule,
  NgxMaskModule,
  MatTooltipModule,

  ComponentsModule,
  DirectivesModule,
  MaterialModule,
  PipesModule,
  ViewTemplatesModule
];

@NgModule({
  imports: [...modules, NgxMaskModule.forRoot()],
  exports: [...modules]
})
export class SharedModule {
}
