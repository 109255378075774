<div class="ag-grid-wrapper" *heaNgLet="state?.pending$ | async as pending">
    <mat-spinner [diameter]="24" class="ag-grid-loader" mode="indeterminate"
                 *ngIf="state?.pending$ | async"></mat-spinner>
    <ag-grid-angular
            class="ag-theme-hea-grid with-pagination"
            [columnDefs]="columnDefs"
            [rowData]="rowData$ | async"
            [gridOptions]="gridOptions"
            [rowSelection]="'multiple'"
            [animateRows]="true"
            (gridSizeChanged)="onResize($event)"
            (gridReady)="onGridReady($event)"
            (rowDoubleClicked)="rowDoubleClicked.emit($event)"
    ></ag-grid-angular>
    <ng-container *ngIf="pagination">
        <mat-paginator [length]="total"
                       [pageSize]="(paginationState$ | async)?.size"
                       [pageIndex]="(paginationState$ | async)?.page"
                       [showFirstLastButtons]="true"
                       [pageSizeOptions]="pageSizeOptions"
                       [disabled]="pending"
                       (page)="paginationChanged.emit($event)"
                       aria-label="Select page">
        </mat-paginator>
    </ng-container>
</div>
