import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-edit',
  template: `
    <div class="flex">
      <button (click)="btnClickedHandler()" class="flex items-center">
        <mat-icon class="size-smd" [svgIcon]="svgIcon"></mat-icon>
      </button>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellEditComponent implements ICellRendererAngularComp {
  svgIcon: 'edit' | 'eye-outline' = 'edit';

  private params: any;

  agInit(params: any): void {
    this.params = params;
    if (params?.svgIconDetectFn) {
      this.svgIcon = params?.svgIconDetectFn(params.data);
    }
  }

  btnClickedHandler() {
    this.params.clicked(this.params.data);
  }

  refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
