import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'hea-entity-create-dialog',
  templateUrl: './entity-create-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityCreateDialogComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() submitBtnText: string;
  @Input() submitBtnDisabled = false;
  @Input() heaBtnPending = false;
  @Output() submitted = new EventEmitter<void>();
}
