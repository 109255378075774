import { Pipe, PipeTransform } from '@angular/core';

import {PaymentMethod} from "../../modules/payment-management/pages/payment-settlement/payment-settlement.types";

@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

  transform(value: string): string {
    return PaymentMethod[value];
  }

}
