import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'hea-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss']
})
export class CardImageComponent implements OnChanges {
  @Input() cardType: string | null = null;

  cardTypeImageMap: { [key: string]: string } = {
    'VISA': 'visa',
    'VISA DEBIT': 'visa-debit',
    'MASTERCARD': 'mastercard',
    'DEBIT MASTERCARD': 'mastercard-debit',
    'AMEX': 'american-express',
    'DISCOVER': 'discover'
  };

  cardImagePath: string | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cardType']) {
      this.setCardImagePath();
    }
  }

  setCardImagePath() {
    const type = this.cardTypeImageMap[this.cardType];
    if (type) {
      this.cardImagePath = `assets/img/cards/${type}.png`;
    }
  }
}
