import {NgModule} from '@angular/core';

import {ToastMsgComponent} from './toast-msg.component';

@NgModule({
  declarations: [ToastMsgComponent],
  exports: [ToastMsgComponent]
})
export class ToastMsgModule {
}
