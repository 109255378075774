import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HttpTransportType } from '@microsoft/signalr';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { NotificationEvent, NotificationMessage } from './notifications.types';
import { NotificationsApiService } from './notifications-api.service';
import { AuthService } from '@core/services/auth';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  studentUpdated$: Observable<NotificationMessage>;
  disbursementStudentUpdated$: Observable<NotificationMessage>;
  demographicFileUpdated$: Observable<NotificationMessage>;
  disbursementFileUpdated$: Observable<NotificationMessage>;
  disbursementCancelled$: Observable<NotificationMessage>;
  studentPaymentUpdated$: Observable<NotificationMessage>;

  private _studentUpdated$ = new BehaviorSubject<NotificationMessage>(null);
  private _disbursementStudentUpdated$ = new BehaviorSubject<NotificationMessage>(null);
  private _demographicFileUpdated$ = new BehaviorSubject<NotificationMessage>(null);
  private _disbursementFileUpdated$ = new BehaviorSubject<NotificationMessage>(null);
  private _disbursementCancelled$ = new BehaviorSubject<NotificationMessage>(null);
  private _studentPaymentUpdated$ = new BehaviorSubject<NotificationMessage>(null);

  constructor(
    private notificationsApiService: NotificationsApiService,
    private authService: AuthService
  ) {
    this.studentUpdated$ = this._studentUpdated$.asObservable();
    this.disbursementStudentUpdated$ = this._disbursementStudentUpdated$.asObservable();
    this.demographicFileUpdated$ = this._demographicFileUpdated$.asObservable();
    this.disbursementFileUpdated$ = this._disbursementFileUpdated$.asObservable();
    this.disbursementCancelled$ = this._disbursementCancelled$.asObservable();
    this.studentPaymentUpdated$ = this._studentPaymentUpdated$.asObservable();
    this.connect();
  }

  connect() {
    const userId = this.authService.getUser()?.uid;

    if (!userId) {
      return;
    }

    this.notificationsApiService.addToGroup(userId, environment.notificationGroupName)
      .pipe(switchMap(() => this.notificationsApiService.negotiate(userId)))
      .subscribe({
        next: (res) => {
          const options = {
            accessTokenFactory: () => res.accessToken,
            transport: HttpTransportType.None,
            withCredentials: false
          };

          let connection = new signalR.HubConnectionBuilder()
            .withUrl(res.url, options)
            .withAutomaticReconnect()
            .build();

          connection.on('notifications', (message: NotificationMessage) => {

            switch (message.Event) {
              case NotificationEvent.recipientEntryUpdated: {
                this._studentUpdated$.next(message);
                break;
              }
              case NotificationEvent.disbursementEntryValid: {
                this._disbursementStudentUpdated$.next(message);
                break;
              }
              case NotificationEvent.disbursementEntryInvalid: {
                this._disbursementStudentUpdated$.next(message);
                break;
              }
              case NotificationEvent.disbursementBatchFileUploadStarted: {
                this._disbursementFileUpdated$.next(message);
                break;
              }
              case NotificationEvent.disbursementBatchVerificationCompleted: {
                this._disbursementFileUpdated$.next(message);
                break;
              }
              case NotificationEvent.recipientBatchFileUploadStarted: {
                this._demographicFileUpdated$.next(message);
                break;
              }
              case NotificationEvent.recipientBatchFileUploadCompleted: {
                this._demographicFileUpdated$.next(message);
                break;
              }
              case NotificationEvent.disbursementRequestCanceled: {
                this._disbursementCancelled$.next(message);
                break;
              }
              case NotificationEvent.studentPayment: {
                this._studentPaymentUpdated$.next(message);
                break;
              }
              default: {
                break;
              }
            }

          });

          connection.start().then(() => {
            console.log('Connected');
          });
        },
        error: (err) => {
          console.log('WS Connection error', err);
        }
      });

  }

  setStudentPaymentUpdated(val: NotificationMessage) {
    this._studentPaymentUpdated$.next(val);
  }
}
