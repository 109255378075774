import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {isHttpErrorResponse} from '@core/common';
import {ToastMsgComponent} from '@shared/components/toast-msg/toast-msg.component';
import {ToastMsgType} from '@shared/components/toast-msg/toast-msg.types';

@Injectable({providedIn: 'root'})
export class NotificationService {
  duration = 5000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private snackBar: MatSnackBar) {
  }

  success(message: string): void {
    this.notify('info', message);
  }

  warn(message: string = 'Something went wrong'): void {
    this.notify('warn', message);
  }

  error(message: string | HttpErrorResponse = 'Something went wrong'): void {
    if (typeof message === 'string') {
      this.notify('error', message);
    } else if (isHttpErrorResponse(message)) {
      this.notify('error', message?.error?.message);
    }
  }

  private notify(type: ToastMsgType, message: string): void {
    this.snackBar.openFromComponent(ToastMsgComponent, {
      duration: this.duration,
      panelClass: type === 'error' ? 'bg-danger' : null,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      data: {
        message,
        type
      }
    });
  }

}
