import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {PipesModule} from '../../pipes';

const components = [
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    MatIconModule,
    PipesModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    ...components
  ]
})
export class FormFieldsModule {
}
