import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorTypes, INITIAL_PAGINATION_CONFIG, ListResponse, Pagination} from '@core/common';
import {ErrorMessageService} from '@core/services/error-message.service';
import {
  BehaviorSubject,
  catchError,
  combineLatest, EMPTY,
  filter,
  finalize,
  map,
  Observable,
  pairwise,
  repeat,
  ReplaySubject,
  switchMap,
  take,
  tap,
  throwError, timeout
} from 'rxjs';

import {
  FilesStatus,
  Student,
  StudentListFilter,
  StudentUpdateReq,
  UploadedFile,
  UploadedFilesFilter
} from './disbursement-import.types';
import {DisbursementImportApiService} from './disbursement-import-api.service';
import {NotificationMessage, NotificationsService} from '@core/services/notifications';

@Injectable({providedIn: 'root'})
export class DisbursementImportService {
  pendingList$: Observable<boolean>;
  errorList$: Observable<HttpErrorResponse>;
  selectedFile$: Observable<UploadedFile>;
  selectedFileDetails$: Observable<UploadedFile>;
  updatePending$: Observable<boolean>;
  updateError$: Observable<HttpErrorResponse>;
  uploadPending$: Observable<boolean>;
  uploadError$: Observable<HttpErrorResponse>;
  initiatePending$: Observable<boolean>;
  initiateError$: Observable<HttpErrorResponse>;
  pendingFilesList$: Observable<boolean>;
  errorFilesList$: Observable<HttpErrorResponse>;
  pagination$: Observable<Pagination>;
  filesPagination$: Observable<Pagination>;

  private _refreshList$ = new BehaviorSubject<boolean>(false);
  private _pendingList$ = new BehaviorSubject<boolean>(false);
  private _errorList$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _refreshSelectedFileDetails$ = new BehaviorSubject<boolean>(false);
  private _pendingFilesList$ = new BehaviorSubject<boolean>(false);
  private _errorFilesList$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _pagination$ = new ReplaySubject<Pagination>(1);
  private _selectedFile$ = new ReplaySubject<UploadedFile>(1);
  private _selectedFileDetails$ = new BehaviorSubject<UploadedFile>(null);
  private _updatePending$ = new BehaviorSubject<boolean>(false);
  private _updateError$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _filter$ = new BehaviorSubject<StudentListFilter>(null);
  private _uploadPending$ = new BehaviorSubject<boolean>(false);
  private _uploadError$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _initiatePending$ = new BehaviorSubject<boolean>(false);
  private _initiateError$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _refreshFilesList$ = new BehaviorSubject<boolean>(false);
  private _filesPagination$ = new ReplaySubject<Pagination>(1);
  private _filesFilter$ = new BehaviorSubject<UploadedFilesFilter>(null);

  constructor(
    private disbursementImportApiService: DisbursementImportApiService,
    private notificationsService: NotificationsService,
  ) {
    this.pendingList$ = this._pendingList$.asObservable();
    this.errorList$ = this._errorList$.asObservable();
    this.selectedFile$ = this._selectedFile$.asObservable();
    this.selectedFileDetails$ = this._selectedFileDetails$.asObservable();
    this.updatePending$ = this._updatePending$.asObservable();
    this.updateError$ = this._updateError$.asObservable();
    this.uploadPending$ = this._uploadPending$.asObservable();
    this.uploadError$ = this._uploadError$.asObservable();
    this.initiatePending$ = this._initiatePending$.asObservable();
    this.initiateError$ = this._initiateError$.asObservable();
    this.pendingFilesList$ = this._pendingFilesList$.asObservable();
    this.errorFilesList$ = this._errorFilesList$.asObservable();
    this.pagination$ = this._pagination$.asObservable();
    this.filesPagination$ = this._filesPagination$.asObservable();
  }

  getUsers(): Observable<ListResponse<Student>> {
    return combineLatest([this._pagination$, this._filter$, this._selectedFile$, this._refreshList$])
      .pipe(
        tap(() => {
          this._pendingList$.next(true);
          this._errorList$.next(null);
        }),
        switchMap(([pagination, filterParams, uploadedFile]) => this.disbursementImportApiService.getUsers(pagination, filterParams, uploadedFile?.fileId)
          .pipe(
            map((list) => ({
              ...list,
              data: list.data.map((student) => ({
                ...student,
                errorsMap: this.getErrorMap(student.errors)
              }))
            })),
            finalize(() => {
              this._pendingList$.next(false);
              this.refreshSelectedFileDetails();
            }),
            catchError((err) => {
              this._errorList$.next(err);
              return throwError(() => err);
            })
          ))
      )
  }

  getSelectedFileDetails(fileId: number): Observable<UploadedFile> {
    return combineLatest([this._refreshSelectedFileDetails$])
      .pipe(
        switchMap(() => this.disbursementImportApiService.getFileById(fileId)
        )
      )
  }

  updateStudent(studentUpdateReq: StudentUpdateReq): Observable<Student> {
    this._updatePending$.next(true);
    this._updateError$.next(null);
    return this.disbursementImportApiService.updateStudent(studentUpdateReq)
      .pipe(
        switchMap(
          (student) => this.notificationsService.disbursementStudentUpdated$
            .pipe(
              timeout(30 * 1000),
              pairwise(),
              filter(notifications => this.notificationMessageChanged(notifications[0], notifications[1])),
              map(() => student),
              catchError((err) => {
                if(err.message === 'Timeout has occurred'){
                  return EMPTY;
                }else{
                  return throwError(() => err);
                }
              })
            )
        ),
        finalize(() => {
          this._updatePending$.next(false);
          this.refreshFilesList();
        }),
        tap(() => {
          this.refreshList();
        }),
        catchError((err) => {
          this._updateError$.next(err);
          return throwError(() => err);
        })
      );
  }

  resetEditStudentState() {
    this._updatePending$.next(false);
    this._updateError$.next(null);
  }

  uploadFile(file: File): Observable<UploadedFile> {
    this._uploadPending$.next(true);
    this._uploadError$.next(null);
    return this.disbursementImportApiService.uploadFile(file)
      .pipe(
        // switchMap((uploadedFile) =>
        //   uploadedFile.status === FilesStatus.failed ? throwError(() => uploadedFile.reason) : of(uploadedFile)
        // ),
        switchMap(
          (file) => this.notificationsService.disbursementFileUpdated$
            .pipe(
              timeout(30 * 1000),
              pairwise(),
              filter(notifications => this.notificationMessageChanged(notifications[0], notifications[1])),
              map(() => file),
              catchError((err) => {
                if(err.message === 'Timeout has occurred'){
                  return EMPTY;
                }else{
                  return throwError(() => err);
                }
              })
            )
        ),
        finalize(() => {
          this._uploadPending$.next(false);
          this.refreshFilesList();
        }),
        tap((uploadedFile) => {
          this.setSelectedFile(uploadedFile);
        }),
        catchError((err) => {
          this._uploadError$.next(err);
          return throwError(() => err);
        })
      )
  }

  initiateDsb(fileId: number): Observable<UploadedFile> {
    this._initiatePending$.next(true);
    this._initiateError$.next(null);
    return this.disbursementImportApiService.initiateDsb(fileId)
      .pipe(
        switchMap(() => this.getFileById(fileId).pipe(
          repeat({delay: 1000}),
          filter((res) =>
            res?.status === FilesStatus.initiated),
          take(1),
        )),
        finalize(() => {
          this._initiatePending$.next(false);
          this.refreshList();
          this.refreshFilesList();
        }),
        catchError((err) => {
          this._initiateError$.next(err);
          return throwError(() => err);
        })
      )
  }

  refreshList(): void {
    this._refreshList$.next(!this._refreshList$.value);
  }

  refreshSelectedFileDetails(): void {
    this._refreshSelectedFileDetails$.next(!this._refreshSelectedFileDetails$.value);
  }

  setPagination(pagination: Pagination): void {
    this._pagination$.next(pagination);
  }

  setSelectedFile(selectedFile: UploadedFile): void {
    this._selectedFile$.next(selectedFile);
  }

  setFilter(filterParams: any): void {
    this._filter$.next(filterParams);
    this.setPagination(INITIAL_PAGINATION_CONFIG);
  }

  getFilter(): StudentListFilter {
    return this._filter$.value;
  }

  getFiles(): Observable<ListResponse<UploadedFile>> {
    return combineLatest([this._filesPagination$, this._filesFilter$, this._refreshFilesList$, this.notificationsService.disbursementFileUpdated$])
      .pipe(
        tap(() => {
          this._pendingFilesList$.next(true);
          this._errorFilesList$.next(null);
        }),
        switchMap(([pagination, filesFilter]) => this.disbursementImportApiService.getFiles(pagination, filesFilter)
          .pipe(
            finalize(() => {
              this._pendingFilesList$.next(false);
            }),
            catchError((err) => {
              this._errorFilesList$.next(err);
              return throwError(() => err);
            })
          ))
      )
  }

  getFileById(fileId: number): Observable<UploadedFile> {
    return this.disbursementImportApiService.getFileById(fileId);
  }

  deleteFile(fileId: number): Observable<UploadedFile> {
    return this.disbursementImportApiService.deleteFile(fileId)
      .pipe(tap(() => {
        setTimeout(()=>{
          this.refreshFilesList();
        }, 1000);
      }))
  }

  deleteStudent(id: number): Observable<Student> {
    return this.disbursementImportApiService.deleteStudent(id)
      .pipe(tap(() => {
        this.refreshList();
      }))
  }

  refreshFilesList(): void {
    this._refreshFilesList$.next(!this._refreshFilesList$.value);
  }

  setFilesPagination(pagination: Pagination): void {
    this._filesPagination$.next(pagination);
  }

  setFilesFilter(filesFilter: UploadedFilesFilter): void {
    this._filesFilter$.next(filesFilter);
    this.setFilesPagination(INITIAL_PAGINATION_CONFIG);
  }

  getFilesFilter(): UploadedFilesFilter {
    return this._filesFilter$.value;
  }

  resetUserFilters() {
    this._filter$.next(null);
  }

  private getErrorMap(errors: ErrorTypes[]): Partial<Record<ErrorTypes, string>> {
    const res: Partial<Record<ErrorTypes, string>> = {};

    errors?.forEach((e) => {
      res[e] = ErrorMessageService.getHttpErrorMessageByCode(e);
    });

    return res;
  }

  private notificationMessageChanged(n1: NotificationMessage, n2: NotificationMessage): boolean {
    return JSON.stringify(n1) !== JSON.stringify(n2);
  }
}
