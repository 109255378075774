import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {FilterPeriodComponent} from '@shared/components/filter-period/filter-period.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [FilterPeriodComponent],
  imports: [
    NgxDaterangepickerMd,
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule
  ],
  exports: [FilterPeriodComponent]
})
export class FilterPeriodModule {
}
