import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipesModule} from '@shared/pipes';

import {ViewTemplateCurrencyComponent} from './view-template-currency';
import {ViewTemplateDateComponent} from './view-template-date';
import {ViewTemplateNumberComponent} from './view-template-number';
import {ViewTemplateStringComponent} from './view-template-string';

const components = [
  ViewTemplateDateComponent,
  ViewTemplateCurrencyComponent,
  ViewTemplateStringComponent,
  ViewTemplateNumberComponent
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, PipesModule],
  exports: [...components]
})
export class ViewTemplatesModule {
}
