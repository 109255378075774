import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES, isListResponse, Pagination} from '@core/common';
import {ColDef, GridOptions, GridReadyEvent, GridSizeChangedEvent, RowDoubleClickedEvent} from 'ag-grid-community';
import {map, Observable, of, tap} from 'rxjs';

import {AgGridState} from './ag-grid-wrapper.type';

@Component({
  selector: 'hea-ag-grid-wrapper',
  templateUrl: './ag-grid-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgGridWrapperComponent<T = unknown> {
  @Input() pageSize = DEFAULT_PAGE_SIZE;
  @Input() pageIndex = 0;
  @Input() pageSizeOptions = DEFAULT_PAGE_SIZES;
  @Input() columnDefs: ColDef[];
  @Input() state: AgGridState<T>;
  @Input() pagination = true;

  @Input() set gridOptions(gridOptions: GridOptions) {
    this._gridOptions = {
      ...gridOptions,
      ...this._defaultGridOptions
    };
  };

  get gridOptions(): GridOptions {
    return this._gridOptions;
  }

  @Output() paginationChanged = new EventEmitter<PageEvent>();
  @Output() rowDoubleClicked = new EventEmitter<RowDoubleClickedEvent>();
  total: number;
  rowData$: Observable<T[]> = of([]);
  paginationState$: Observable<Pagination>;

  private _defaultGridOptions = {
    suppressScrollOnNewData: true
  };
  private _gridOptions: GridOptions = {
    ...this._defaultGridOptions
  };

  onResize(event: GridSizeChangedEvent<T>): void {
    event.api.sizeColumnsToFit();
  }

  onGridReady(params: GridReadyEvent<T>): void {
    params.api.sizeColumnsToFit();
    this.paginationState$ = this.state.pagination$;
    this.rowData$ = this.state.data$
      .pipe(
        tap((res) => {
          if (isListResponse(res)) {
            this.total = res.totalElements;
          }
          setTimeout(() => {
            params.api.sizeColumnsToFit();
          })
        }),
        map((res) => {
          if (isListResponse(res)) {
            return res.data;
          } else {
            return res;
          }
        }),
      );
  }
}
