import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'hea-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.initCustomMaterialIcons();
  }

  private initCustomMaterialIcons(): void {
    this.matIconRegistry
      .addSvgIconSet(this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/symbol-defs.svg'));
  }
}
