import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {BehaviorSubject, catchError, filter, finalize, of, switchMap, take, tap} from 'rxjs';
import {ConfirmActionDialogComponent, ConfirmActionDialogData} from '@shared/components/confirm-action-dialog';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '@core/services';
import {DisbursementsService} from '../../../../modules/credit-disbursement/entity';

@Component({
  selector: 'hea-ag-cell-cancel',
  template: `
    <div class="flex">
      <button (click)="onClick()"
              matTooltip="Cancel Disbursement"
              [heaBtnPending]="pending$ | async"
              class="flex items-center mat-mdc-icon-button">
        <mat-icon class="size-smd" svgIcon="close"></mat-icon>
      </button>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellCancelComponent implements ICellRendererAngularComp {
  private params: any;
  pending$ = new BehaviorSubject(false);

  constructor(
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    private disbursementsService: DisbursementsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  onClick() {
    this.pending$.next(true);
    const confirmActionDialogData: ConfirmActionDialogData = {
      title: 'Are you sure you want to cancel disbursement?',
      titleColor: 'color-danger',
      svgIcon: 'error',
      confirmBtnText: 'Yes, Cancel',
      cancelBtnText: 'No',
    };

    this.matDialog.open<ConfirmActionDialogComponent, ConfirmActionDialogData>(ConfirmActionDialogComponent, {
      width: '450px',
      data: confirmActionDialogData
    }).afterClosed()
      .pipe(
        take(1),
        filter(Boolean),
        tap(() => {
          this.notificationService.success('Cancel request sent');
        }),
        switchMap(() => this.disbursementsService.cancelDisbursement(this.params?.data?.transactionId)),
        tap(() => {
          this.notificationService.success('Disbursement successfully canceled');
          this.changeDetectorRef.markForCheck();
        }),
        catchError((err) => {
          this.notificationService.error(err);
          return of(null);
        }),
        finalize(() => {
          this.pending$.next(false);
        })
      )
      .subscribe();
  }

  refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
