import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import {FormFieldErrorComponent} from './form-field-error.component';

@NgModule({
  declarations: [FormFieldErrorComponent],
  imports: [MatIconModule],
  exports: [FormFieldErrorComponent]
})
export class FormFieldErrorModule {

}
