import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {SchoolBalanceTypes} from './school-balance.types';

@Injectable({providedIn: 'root'})
export class SchoolBalanceApiService {

  constructor(private httpClient: HttpClient) {
  }

  getSchoolBalance(): Observable<SchoolBalanceTypes> {
    return this.httpClient.get<SchoolBalanceTypes>(`${environment.baseURL}/api/disbursements/balance`)
  }
}
