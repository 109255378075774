import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ErrorTypes} from '@core/common';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-string',
  template: `
    <div class="flex items-center"
         [class.color-danger]="this.errors?.length">
      <mat-icon *ngIf="this.errors?.length"
                svgIcon="information-outline"
                class="size-smd mr-2 cursor-pointer"
                [matTooltip]="errors[0]"></mat-icon>
      {{value  | heaValueOrDefault: defaultValue}}
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellStringComponent implements ICellRendererAngularComp {
  value: string;
  defaultValue = 'N/A';
  errors = [];

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.defaultValue = params['defaultValue'] || 'N/A';
    if (params['errorCodes']?.length) {
      this.errors = this.getErrorMessagesFromListByCodes(params?.data?.errorsMap, params['errorCodes']);
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.defaultValue = params['defaultValue'] || 'N/A';
    if (params['errorCodes']?.length) {
      this.errors = this.getErrorMessagesFromListByCodes(params?.data?.errorsMap, params['errorCodes']);
    }
    return false;
  }

  private getErrorMessagesFromListByCodes(errorsMap: Record<ErrorTypes, string>, codes: ErrorTypes[]): string[] {
    if (!errorsMap) {
      return [];
    }
    return codes.map((code) => errorsMap[code]).filter(n => n);
  }

}
