import {HttpErrorResponse} from '@angular/common/http';
import {PageEvent} from '@angular/material/paginator';
import {ListResponse, Pagination} from '@core/common/common.types';

export const isArraysAreEqual = <T>(arr1: T[], arr2: T[]): boolean => {
  if (!arr1 && !arr2) {
    return true;
  }

  if (!arr1?.length && !arr2?.length) {
    return true;
  }

  if (arr1 && arr1?.length && arr2 && arr2?.length) {
    return arr1?.length === arr2?.length
      && arr1?.every((value) => value === arr2?.find(d => d === value));
  }

  return false;
}

/**
 * This method works correctly only with POJO!
 */
export const hasDtoChanged = (dto1: Record<string, any>, dto2: Record<string, any>): boolean => {
  try {
    return JSON.stringify(dto1) === JSON.stringify(dto2);
  } catch (e) {
    return false;
  }
}

export const matPageEventToPagination = (pageEvent: PageEvent): Pagination => {
  return {
    page: pageEvent.pageIndex,
    size: pageEvent.pageSize,
  }
}

export const isListResponse = (listResponse: ListResponse | any): listResponse is ListResponse => {
  return (listResponse as ListResponse).totalElements !== undefined;
}

export const isHttpErrorResponse = (httpErrorResponse: HttpErrorResponse | any): httpErrorResponse is HttpErrorResponse => {
  return (httpErrorResponse as HttpErrorResponse).error?.message !== undefined;
}
