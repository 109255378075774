import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Period} from '@shared/components/filter-period/filter-period.types';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {TimePeriod} from 'ngx-daterangepicker-material/daterangepicker.component';
import {Subject, tap} from 'rxjs';
import {DaterangepickerDirective} from 'ngx-daterangepicker-material';

@UntilDestroy()
@Component({
  selector: 'hea-filter-period',
  templateUrl: './filter-period.component.html',
  styleUrls: ['./filter-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterPeriodComponent),
      multi: true
    }
  ],
})
export class FilterPeriodComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() format = 'YYYY-MM-DDTHH:mm:ss';
  @Input() startDate = dayjs().subtract(6, 'days');
  @ViewChild(DaterangepickerDirective, {static: false}) pickerDirective!: DaterangepickerDirective;
  ranges: any;
  disabled = false;
  filterPeriodCtrl = new FormControl<TimePeriod>({startDate: this.startDate, endDate: dayjs()});

  private dayjsUtc: any = dayjs.extend(dayjsPluginUTC);

  get value(): Period {
    return this.timePeriodToPeriod(this.filterPeriodCtrl.getRawValue());
  }

  set value(val: Period) {
    this.filterPeriodCtrl.patchValue(this.periodToTimePeriod(val));
    this.onChange(this.value);
    this.onTouch(this.value);
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,) {
  }

  ngOnInit(): void {
    this.buildRanges();

    this.filterPeriodCtrl.valueChanges
      .pipe(
        tap((v) => {
          this.onChange(this.value);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onRangeClicked(e) {
    this.pickerDirective.picker.applyBtn.disabled = false;
  }

  ngAfterViewInit(): void {
    this.pickerDirective.startDateChanged.subscribe((v) => {
      this.pickerDirective.picker.applyBtn.disabled = true;
    });
    this.pickerDirective.endDateChanged.subscribe((v) => {
      this.pickerDirective.picker.applyBtn.disabled = false;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onChange = (_: any) => {
  };
  onTouch = (_: any) => {
  };

  writeValue(value: Period): void {
    this.value = value;
  }

  registerOnChange(fn: () => {}): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  private buildRanges(): void {
    this.ranges = {
      'Today': [dayjs(), dayjs()],
      'Yesterday': [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
      'Last 7 Days': [dayjs().subtract(6, 'days'), dayjs()],
      'Last 30 Days': [dayjs().subtract(29, 'days'), dayjs()],
      'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
      'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
    };
  }

  private timePeriodToPeriod(timePeriod: TimePeriod): Period | null {
    if (!timePeriod?.startDate) {
      return null;
    }

    return {
      dateFrom: this.dayjsUtc(timePeriod.startDate.format(this.format)).startOf('day').utc().format(this.format),
      dateTo: this.dayjsUtc(timePeriod.endDate.format(this.format)).endOf('day').utc().format(this.format)
    }
  }

  private periodToTimePeriod(period: Period): TimePeriod | null {
    if (!period?.dateFrom) {
      return null;
    }
    return {
      startDate: this.dayjsUtc.utc(period.dateFrom).local(),
      endDate: this.dayjsUtc.utc(period.dateTo).local()
    }
  }

}
