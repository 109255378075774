import {ScrollingModule} from '@angular/cdk/scrolling';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {MatSelectInfiniteScrollDirective} from './directives/mat-select-infinite-scroll.directive';

const modules = [
  MatButtonModule,
  MatDialogModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatSelectModule,
  NgxMatSelectSearchModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatInputModule,
  MatIconModule,
  MatCardModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  ScrollingModule,
  MatMenuModule,
  MatTabsModule,
  MatButtonModule,
  MatMenuModule,
];

@NgModule({
  imports: [...modules],
  exports: [
    ...modules,
    MatSelectInfiniteScrollDirective
  ],
  declarations: [
    MatSelectInfiniteScrollDirective
  ]
})
export class MaterialModule {
}
