import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'hea-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldErrorComponent {
  @Input() message = 'Field is required';
}
