import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ListResponse, Pagination} from '@core/common';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';

import {
  Student,
  StudentListFilter,
  StudentUpdateReq,
  UploadedFile,
  UploadedFilesFilter
} from './disbursement-import.types';

@Injectable({providedIn: 'root'})
export class DisbursementImportApiService {

  constructor(private httpClient: HttpClient) {
  }

  getUsers(pagination: Pagination, filter: StudentListFilter, fileId: number): Observable<ListResponse<Student>> {
    const params: any = {
      page: pagination.page,
      size: pagination.size,
      ...filter
    }
    return this.httpClient.get<ListResponse<Student>>(`${environment.baseURL}/api/disbursement-import/files/${fileId}/disbursements`, {params});
  }

  getFiles(pagination: Pagination, filter: UploadedFilesFilter): Observable<ListResponse<UploadedFile>> {
    const params: any = {
      page: pagination.page,
      size: pagination.size,
      ...filter
    }
    return this.httpClient.get<ListResponse<UploadedFile>>(`${environment.baseURL}/api/disbursement-import/files`, {params});
  }

  getFileById(fileId: number): Observable<UploadedFile> {
    return this.httpClient.get<UploadedFile>(`${environment.baseURL}/api/disbursement-import/files/${fileId}`);
  }

  uploadFile(file: File): Observable<UploadedFile> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<UploadedFile>(`${environment.baseURL}/api/disbursement-import/files`, formData)
  }

  updateStudent(studentUpdateReq: StudentUpdateReq): Observable<Student> {
    return this.httpClient.put<Student>(`${environment.baseURL}/api/disbursement-import/disbursements/${studentUpdateReq.id}`, studentUpdateReq);
  }

  initiateDsb(fileId: number): Observable<UploadedFile> {
    return this.httpClient.post<UploadedFile>(`${environment.baseURL}/api/disbursement-import/files/${fileId}/disbursements/initiate`, null)
  }

  deleteFile(fileId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.baseURL}/api/disbursement-import/files/${fileId}`);
  }

  deleteStudent(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.baseURL}/api/disbursement-import/disbursements/${id}`);
  }
}
