<mat-form-field class="w-full" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <mat-spinner *ngIf="isRequestPending && total"
               matPrefix
               [diameter]="16"
               class="mx-3"
               mode="indeterminate"></mat-spinner>
  <mat-select [formControl]="formControl"
              [multiple]="multiple"
              [placeholder]="placeholder"
              [complete]="fetchedCount === total"
              (infiniteScroll)="getNextBatch()"
              (selectionChange)="selectionChange.emit()"
              heaMatSelectInfiniteScroll>
    <mat-option [value]="null">N/A</mat-option>
    <mat-option *ngFor="let option of options$ | async" [value]="option.filename">{{option.filename}}</mat-option>
  </mat-select>
</mat-form-field>
