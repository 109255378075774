import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StatusModule} from '@shared/components/status';

import {DisbursementFileStatusComponent} from './disbursement-file-status.component';

@NgModule({
  declarations: [DisbursementFileStatusComponent],
  imports: [StatusModule, MatIconModule, MatTooltipModule],
  exports: [DisbursementFileStatusComponent]
})
export class DisbursementFileStatusModule {
}
