import {AuthService} from "@core/services/auth";

const getQueryParam = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getTokenResolver = (authService: AuthService) => (): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = getQueryParam('token') || user?.stsTokenManager?.refreshToken;
  return token ? authService.signInWithToken(token) : Promise.resolve();
};
