import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-elements-counter',
  template: `{{value | heaTruncate | heaValueOrDefault}}<span *ngIf="elementsCount" class="ml-2">{{elementsCount}}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellElementsCounterComponent implements ICellRendererAngularComp {
  elements: string[];
  value: string;


  get elementsCount(): string {
    return this.elements?.length > 1 ? `+${this.elements.length - 1}` : '';
  }

  agInit(params: ICellRendererParams): void {
    this.elements = params.value;
    this.value = params.value?.length ? params.value[0] : '';
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value?.length ? params.value[0] : '';
    return false;
  }
}
