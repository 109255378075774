import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

import {MaterialModule} from '../../material';
import {InfiniteScrollSelectorComponent} from './infinite-scroll-selector.component';

const components = [
  InfiniteScrollSelectorComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...components
  ]
})
export class InfiniteScrollSelectorModule {
}
