import {Period} from '@shared/components/filter-period/filter-period.types';

export interface Disbursement {
  transactionId: number;
  studentId: string;
  termCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  amount: number;
  date: string;
  paidBy: string;
  status: DisbursementStatus;
  checkReason: string;
  created: string;
  updated: string;
  noticeSentOn: string;
  checkSeriesNumber: string;
  redeemedOn: string;
}

export enum DisbursementStatus {
  redeemed = 'Redeemed',
  success = 'Success',
  unDelivered = 'Undelivered',
  pending = 'Pending',
  checkCashed = 'CheckCashed',
  checkIssued = 'CheckIssued',
  checkExpired = 'CheckExpired',
  checkRequested = 'CheckRequested',
  failed = 'Failed',
  canceled = 'Canceled',
  unknown = 'Unknown',
  expired = 'Expired',
  terminated = 'Terminated',
  returned = 'Returned',
}

export interface DisbursementsListFilter extends Period {
  search?: string;
  status?: DisbursementStatus;
  filename?: string;
}

export interface RedeemTotalsTypes {
  redeemedCount: number;
  redeemedAmount: number;
  pendingCount: number;
  pendingAmount: number;
  checksIssuedOrRequestedCount: number;
  checksIssuedOrRequestedAmount: number;
}
