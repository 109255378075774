export interface Environment {
  production: boolean;
  whiteLabeling: WhiteLabeling;
  baseURL: string;
  portalApiUrl: string;
  bursarApiUrl: string;
  firebaseApiUrl: string;
  ocpSubscriptionKey: string;
  notificationGroupName: string;
  firebase: FirebaseConfig;
}

export interface WhiteLabeling {
  tenantId: Tenant,
  logoUrl: string;
}

export enum Tenant {
  hank = 'hank',
  clarendon = 'clarendon',
  clinton = 'clinton',
  lubbock = 'lubbock',
  seminole = 'seminole',
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  appId: string;
}
