import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {INITIAL_PAGINATION_CONFIG, ListResponse, Pagination} from '@core/common';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  filter,
  finalize,
  map,
  Observable,
  pairwise,
  ReplaySubject,
  switchMap,
  tap,
  throwError,
  timeout
} from 'rxjs';

import {Disbursement, DisbursementsListFilter, RedeemTotalsTypes} from './disbursements.types';
import {DisbursementsApiService} from './disbursements-api.service';
import {NotificationMessage, NotificationsService} from '@core/services/notifications';
import {NotificationService} from '@core/services';
import {SchoolBalanceService} from '../school-balance/entity';

@Injectable({providedIn: 'root'})
export class DisbursementsService {
  pendingDisbursementsList$: Observable<boolean>;
  errorDisbursementsList$: Observable<HttpErrorResponse>;
  pagination$: Observable<Pagination>;
  disbursementsFilter$: Observable<DisbursementsListFilter>;
  pendingTotals$: Observable<boolean>;
  errorTotals$: Observable<HttpErrorResponse>;
  pendingConvertToCheck$: Observable<boolean>;
  errorConvertToCheck$: Observable<HttpErrorResponse>;
  cancelPending$: Observable<boolean>;

  private readonly dateFormat = 'YYYY-MM-DDTHH:mm:ss';
  private dayjsUtc = dayjs.extend(dayjsPluginUTC);
  private _refreshDisbursementsList$ = new BehaviorSubject<boolean>(false);
  private _pendingDisbursementsList$ = new BehaviorSubject<boolean>(false);
  private _errorDisbursementsList$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _pagination$ = new ReplaySubject<Pagination>(1);
  private _disbursementsFilter$ = new BehaviorSubject<DisbursementsListFilter>({
    // @ts-ignore
    dateFrom: this.dayjsUtc().utc().subtract(3, 'month').startOf('day').format(this.dateFormat),
    // @ts-ignore
    dateTo: this.dayjsUtc().utc().endOf('day').format(this.dateFormat),
  });
  private _pendingTotals$ = new BehaviorSubject<boolean>(false);
  private _errorTotals$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _pendingConvertToCheck$ = new BehaviorSubject<boolean>(false);
  private _errorConvertToCheck$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _cancelPending$ = new BehaviorSubject<boolean>(false);
  private _refreshTotals$ = new BehaviorSubject<boolean>(false);

  constructor(
    private disbursementsApiService: DisbursementsApiService,
    private notificationsService: NotificationsService,
    private notificationService: NotificationService,
    private schoolBalanceService: SchoolBalanceService,
  ) {
    this.pendingDisbursementsList$ = this._pendingDisbursementsList$.asObservable();
    this.errorDisbursementsList$ = this._errorDisbursementsList$.asObservable();
    this.pagination$ = this._pagination$.asObservable();
    this.disbursementsFilter$ = this._disbursementsFilter$.asObservable();
    this.pendingTotals$ = this._pendingTotals$.asObservable();
    this.errorTotals$ = this._errorTotals$.asObservable();
    this.pendingConvertToCheck$ = this._pendingConvertToCheck$.asObservable();
    this.errorConvertToCheck$ = this._errorConvertToCheck$.asObservable();
    this.cancelPending$ = this._cancelPending$.asObservable();
  }

  getDisbursements(): Observable<ListResponse<Disbursement>> {
    return combineLatest([this._pagination$, this._disbursementsFilter$, this._refreshDisbursementsList$])
      .pipe(
        tap(() => {
          this._pendingDisbursementsList$.next(true);
          this._errorDisbursementsList$.next(null);
        }),
        switchMap(([pagination, disbursementsFilter]) => this.disbursementsApiService.getDisbursements(pagination, disbursementsFilter)
          .pipe(
            finalize(() => {
              this._pendingDisbursementsList$.next(false);
            }),
            catchError((err) => {
              this._errorDisbursementsList$.next(err);
              return throwError(() => err);
            })
          ))
      )
  }

  getRedeemTotals(): Observable<RedeemTotalsTypes> {
    return combineLatest([this._disbursementsFilter$, this._refreshTotals$])
      .pipe(
        switchMap(([filter]) => this.disbursementsApiService.getRedeemTotals(filter)
          .pipe(
            tap(() => {
              this._pendingTotals$.next(true);
              this._errorTotals$.next(null);
            }),
            finalize(() => {
              this._pendingTotals$.next(false);
            }),
            catchError((err) => {
              this._errorTotals$.next(err);
              return throwError(() => err);

            })
          ))
      )
  }


  cancelDisbursement(transactionId: number): Observable<Disbursement> {
    this._cancelPending$.next(true);
    return this.disbursementsApiService.cancelDisbursement(transactionId)
      .pipe(
        switchMap(
          (student) => this.notificationsService.disbursementCancelled$
            .pipe(
              timeout(30 * 1000),
              pairwise(),
              filter(notifications => this.notificationMessageChanged(notifications[0], notifications[1])),
              map(() => student),
              catchError((err) => {
                if (err.message === 'Timeout has occurred') {
                  this.notificationService.error('Cancel request Timed Out');
                  return EMPTY;
                } else {
                  return throwError(() => err);
                }
              })
            )
        ),
        tap(() => {
          this.refreshTotals();
          this.refreshDisbursementsList();
          this.refreshSchoolBalance();
        }),
        finalize(() => {
          this._cancelPending$.next(false);
          this.refreshTotals();
          this.refreshDisbursementsList();
          this.refreshSchoolBalance();
        }))
  }

  convertToCheck(transactionId: number): Observable<Disbursement> {
    return this.disbursementsApiService.convertDisbursement(transactionId)
      .pipe(tap(() => {
          this._pendingConvertToCheck$.next(true);
          this._errorConvertToCheck$.next(null);
          this.refreshTotals();
          this.refreshDisbursementsList();
        }),
        finalize(() => {
          this._pendingConvertToCheck$.next(false)
        }),
        catchError((err) => {
          this._errorConvertToCheck$.next(err);
          return throwError(() => err);
        })
      )
  }

  refreshDisbursementsList(): void {
    this._refreshDisbursementsList$.next(!this._refreshDisbursementsList$.value);
  }

  setDisbursementsFilter(filesFilter: DisbursementsListFilter): void {
    this._disbursementsFilter$.next(filesFilter);
    this.setPagination(INITIAL_PAGINATION_CONFIG);
  }

  getDisbursementsFilter(): DisbursementsListFilter {
    return this._disbursementsFilter$.value;
  }

  setPagination(pagination: Pagination): void {
    this._pagination$.next(pagination);
  }

  refreshTotals(): void {
    this._refreshTotals$.next(!this._refreshTotals$.value);
  }

  refreshSchoolBalance(): void {
    this.schoolBalanceService.refreshBalance();
  }

  private notificationMessageChanged(n1: NotificationMessage, n2: NotificationMessage): boolean {
    return JSON.stringify(n1) !== JSON.stringify(n2);
  }

}
