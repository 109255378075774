import {Injectable} from '@angular/core';
import { UrlTree } from '@angular/router';
import {AuthService} from '@core/services/auth';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthGuard  {

  constructor(private authService: AuthService) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isLoggedIn) {
      this.authService.signOut();
      return false;
    }
    return true;
  }
}
