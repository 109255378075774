import {EmailAuthCredential} from 'firebase/auth';


export class TokenEmailAuthCredential extends EmailAuthCredential{
  tokenResponse = {};
  /** @internal */
  async _getIdTokenResponse(){
      return this.tokenResponse;
  }
  setTokenResponse(data){
    this.tokenResponse = data;
  }
}
