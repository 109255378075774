import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ALL_AG_STATUSES_MAP} from './ag-cell-status.constants';

@Component({
  selector: 'hea-ag-cell-status',
  template: `
    <div class="flex items-center">
      <mat-icon class="pointer-events-none"
                [svgIcon]="statusMap[value]?.icon"
                [ngClass]="statusMap[value]?.cssClass">
      </mat-icon>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellStatusComponent implements ICellRendererAngularComp {
  value: string;
  statusMap = ALL_AG_STATUSES_MAP;

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }

}
