import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpAuthInterceptor} from '@core/interceptors';
import {HttpErrorInterceptor} from '@core/interceptors/http-error-interceptor';
import {getTokenResolver} from "@core/resolvers/get-token.resolver";
import {AuthService} from "@core/services/auth";
import {environment} from '@environments/environment';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxPermissionsModule} from 'ngx-permissions';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ContactUsModule} from './modules/contact-us';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    ContactUsModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getTokenResolver,
      deps: [AuthService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
