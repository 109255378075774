import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'hea-view-template-number',
  templateUrl: './view-template-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTemplateNumberComponent {
  @Input() value: number;
  @Input() color: string;
  @Input() size: string;
  @Input() strong = false;
  @Input() isDecimal = false;
}
