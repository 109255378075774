import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-date',
  template: `<div>{{(value | date:format) | heaValueOrDefault}}</div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellDateComponent implements ICellRendererAngularComp {
  value: string | Date;
  format: string;

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.format = params['format'];
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }

}
