import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AgCellCancelComponent} from '@shared/components/ag-cells/ag-cell-cancel';
import {AgCellConvertComponent} from '@shared/components/ag-cells/ag-cell-convert/ag-cell-convert.component';
import {AgCellDemographicDownloadComponent} from '@shared/components/ag-cells/ag-cell-demographic-download';
import {AgCellDisbursementsStatusComponent} from '@shared/components/ag-cells/ag-cell-disbursements-status';
import {AgCellDownloadComponent} from '@shared/components/ag-cells/ag-cell-download';
import {AgCellEditComponent} from '@shared/components/ag-cells/ag-cell-edit';
import {DisbursementFileStatusModule} from '@shared/components/disbursement-file-status';
import {DisbursementStatusModule} from '@shared/components/disbursement-status';
import {StatusModule} from '@shared/components/status';
import {DirectivesModule} from '@shared/directives';

import {PipesModule} from '../../pipes';
import {AgCellCurrencyComponent} from './ag-cell-currency';
import {AgCellDateComponent} from './ag-cell-date';
import {AgCellDeleteComponent} from './ag-cell-delete';
import {AgCellDisbursementFileStatusComponent} from './ag-cell-disbursement-file-status';
import {AgCellElementsCounterComponent} from './ag-cell-elements-counter';
import {AgCellNumberComponent} from './ag-cell-number';
import {AgCellStatusComponent} from './ag-cell-status';
import {AgCellStringComponent} from './ag-cell-string';

const components = [
  AgCellDateComponent,
  AgCellDeleteComponent,
  AgCellNumberComponent,
  AgCellStatusComponent,
  AgCellStringComponent,
  AgCellElementsCounterComponent,
  AgCellStringComponent,
  AgCellCurrencyComponent,
  AgCellEditComponent,
  AgCellDownloadComponent,
  AgCellDisbursementsStatusComponent,
  AgCellDisbursementFileStatusComponent,
  AgCellDemographicDownloadComponent,
  AgCellCancelComponent,
  AgCellConvertComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    MatIconModule,
    PipesModule,
    DirectivesModule,
    StatusModule,
    MatButtonModule,
    DisbursementStatusModule,
    DisbursementFileStatusModule,
    MatTooltipModule
  ],
  exports: [
    ...components
  ]
})
export class AgCellsModule {
}
