import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';

import {StatusComponent} from './status.component';

@NgModule({
  declarations: [StatusComponent],
  imports: [
    MatTooltipModule
  ],
  exports: [StatusComponent]
})
export class StatusModule {
}
