import {ChangeDetectionStrategy, Component, Inject, Input} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

import {ToastMsgData, ToastMsgType} from './toast-msg.types';

@Component({
  selector: 'hea-toast-msg',
  templateUrl: './toast-msg.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastMsgComponent {
  @Input() message: string;
  @Input() type: ToastMsgType = 'info';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ToastMsgData) {
    this.message = data?.message;
    this.type = data?.type;
  }
}
