import { Pipe, PipeTransform } from '@angular/core';

import {PaymentType} from "../../modules/payment-management/pages/payment-settlement/payment-settlement.types";
@Pipe({
  name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {
  transform(value: string): string {
    return PaymentType[value];
  }

}
