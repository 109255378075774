import { Environment, Tenant } from '@environments/environment.types';
export const environment = {
  production: false,
  whiteLabeling: {
    tenantId: Tenant.hank,
    logoUrl: 'assets/logo/hank/hank.png'
  },
  baseURL: 'https://hank-edu-admin-dev.uptempo.tech',
  portalApiUrl: 'https://hank-edu-admin-portal-dev.azure-api.net/',
  bursarApiUrl: 'https://bursar-payment-dev-gw.uptempo.tech',
  firebaseApiUrl: 'https://identitytoolkit.googleapis.com/v1/',
  ocpSubscriptionKey: '9ed6d1a3ebab4c4c9cdcd4932e0da8a5',
  notificationGroupName: '6925a96d-d68e-42c5-9306-eaa42255738a',
  firebase: {
    apiKey: 'AIzaSyBPrUdJSXqvxSaEXSE1CAjBXvK7qk5hKH8',
    authDomain: 'hank-edu-dev-b021a.firebaseapp.com',
    projectId: 'hank-edu-dev-b021a',
    appId: '1:379101347457:web:5f7ed0ecc4ee134bf3d780'
  }
};
