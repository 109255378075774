export interface BaseEntity<T = string> {
  id: T;
  name?: string;
}

export interface ListResponse<T = any> {
  data: T[];
  pageNumber: number;
  totalPages: number;
  totalElements: number;
}

export interface Pagination {
  page: number;
  size: number;
}
