import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {DisbursementStatus} from '../../../../modules/credit-disbursement/entity';

@Component({
  selector: 'hea-ag-cell-disbursements-status',
  template: `
    <hea-disbursements-status [status]="status"
                              [truncate]="truncate"
                              [tooltipMessage]="tooltipMessage"
    ></hea-disbursements-status>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellDisbursementsStatusComponent implements ICellRendererAngularComp {
  status: DisbursementStatus;
  truncate = 36;
  tooltipMessage: string;

  agInit(params: ICellRendererParams): void {
    this.status = params?.data?.status;
    this.tooltipMessage = params?.data?.checkReason;
    this.truncate = params['truncate'] || this.truncate;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params?.data?.status;
    this.tooltipMessage = params?.data?.checkReason;
    this.truncate = params['truncate'] || this.truncate;
    return false;
  }
}


