<button mat-icon-button
        class='flex items-center btn-position'
        (click)='close()'>
  <mat-icon class='color-medium' [svgIcon]="'close'"></mat-icon>
</button>

<div class='flex flex-col justify-center items-center contact-popup-content'>

  <h1 class='color-dark  fs-xl font-bold contact-popup-title'>Contact Us</h1>
  <mat-icon class='size-xl  contact-popup-image' svgIcon='contact-us'></mat-icon>
  <h3 class='color-dark pt-2 text-lg font-bold  mt-6'>Need Help?</h3>

  <p class='pt-2 color-medium text-base'>Hank specialists are standing by</p>

  <div class='flex items-center pt-2'>
    <p class='color-medium text-base'>Call now:&nbsp;</p>
    <a [href]="'tel:866-847-1747'" class='color-primary'>
      866-847-1747</a>
  </div>

  <p class='pt-2 color-medium text-base mb-0'>Our hours of operation:</p>
  <p class='pt-2 color-medium text-base'>Monday-Friday 9am-5pm ET</p>

  <div class='flex items-center pt-2 pb-8'>
    <p class='color-medium text-base'>Email us:&nbsp;</p>
    <a [href]="'mailto:schoolsupport@hankpayments.com'" class='color-primary'>
      schoolsupport&#64;hankpayments.com</a>
  </div>

</div>
