import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-delete',
  template: `
    <div class="flex">
      <button (click)="onClick()" class="flex items-center">
        <mat-icon class="size-smd" svgIcon="delete"></mat-icon>
      </button>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellDeleteComponent implements ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  onClick() {
    this.params.clicked(this.params.data);
  }

  refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
