import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {FilesStatus} from '../../../../modules/disbursement-import/entity';

@Component({
  selector: 'hea-ag-cell-disbursement-file-status',
  template: `
    <hea-disbursement-file-status [status]="status"
                                  [truncate]="truncate"></hea-disbursement-file-status>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellDisbursementFileStatusComponent implements ICellRendererAngularComp {
  status: FilesStatus;
  truncate = 36;

  agInit(params: ICellRendererParams): void {
    this.status = params?.data?.status;
    this.truncate = params['truncate'] || this.truncate;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params?.data?.status;
    this.truncate = params['truncate'] || this.truncate;
    return false;
  }
}


