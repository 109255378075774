import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards';

import { AppComponent } from './app.component';
const routes: Routes = [
  {
    path: '', component: AppComponent, children: [
      { path: 'auth', loadChildren: () => import('./modules/auth').then(m => m.AuthModule) },
      {
        path: '', loadChildren: () => import('./modules/sidenav').then(m => m.SidenavModule),
        canActivate: [AuthGuard]
      },
      { path: 'forbidden', loadChildren: () => import('./modules/forbidden').then(m => m.ForbiddenModule) },
      { path: 'receipt', loadChildren: () => import('./modules/receipt').then(m => m.ReceiptModule) },
      { path: '**', loadChildren: () => import('./modules/no-content').then(m => m.NoContentModule) }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
