import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'heaFullName',
  pure: true
})
export class FullNamePipe implements PipeTransform {
  transform(value: { firstName?: string; lastName?: string; }): string | null {
    return [value?.firstName, value?.lastName].filter(Boolean).join(' ') || null;
  }
}
