<button mat-icon-button
        class="flex items-center btn-position"
        (click)="close()">
  <mat-icon class="color-medium" [svgIcon]="'close'"></mat-icon>
</button>

<div class="text-center">

  <div mat-dialog-content>
    <mat-icon *ngIf="confirmActionDialogData?.svgIcon"
              class="size-lmd"
              [color]="confirmActionDialogData?.svgIconColor"
              [svgIcon]="confirmActionDialogData?.svgIcon">
    </mat-icon>
    <h1 [ngClass]="confirmActionDialogData?.titleColor || 'color-dark'"
        class="pt-2 fs-xl font-medium">
      {{confirmActionDialogData?.title}}</h1>
    <p class="pt-2 color-medium fs-sm">{{confirmActionDialogData?.description}}</p>
  </div>

  <div mat-dialog-actions align="center" class="pt-0">

    <button mat-stroked-button
            color="primary"
            (click)="close()">
      {{confirmActionDialogData?.cancelBtnText || 'Cancel'}}
    </button>

    <button mat-raised-button
            color="primary"
            class="ml-4"
            (click)="confirm()">
      {{confirmActionDialogData?.confirmBtnText || 'Confirm'}}
    </button>

  </div>
</div>
