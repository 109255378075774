import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '@environments/environment';

@Pipe({
  name: 'assetsSource',
  pure: true
})
export class AssetsSourcePipe implements PipeTransform {
  private assetsURL = environment.whiteLabeling.tenantId;

  transform(imgName: string): string {
    return `assets/${this.assetsURL}/${imgName}`;
  }

}
