import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {StatusType} from '@shared/components/status/status.types';

@Component({
  selector: 'hea-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent {
  @Input() message: string;
  @Input() tooltipMessage: string;
  @Input() type: StatusType = 'info';
}
