import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';

import {DirectivesModule} from '../../directives';
import {EntityCreateDialogComponent} from './entity-create-dialog.component';

const components = [
  EntityCreateDialogComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    DirectivesModule,
    MatButtonModule
  ],
  exports: [
    ...components
  ]
})
export class EntityCreateDialogModule {
}
