import {Pipe, PipeTransform} from '@angular/core';
import {ErrorMessageService} from '@core/services/error-message.service';

@Pipe({
  name: 'heaHttpErrorMessage',
  pure: true
})
export class HttpErrorMessagePipe implements PipeTransform {
  transform(error: any): any {
    if (error) {
      if (error.error && error.error?.message) {
        return error.error?.message;

      } else if (typeof error === 'string' || error instanceof String) {

        return ErrorMessageService.getErrorWithMessage(error as any);

      } else {
        return 'Something wrong';
      }

    } else {
      return null;
    }
  }
}
