import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '@environments/environment';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-download',
  template: `<a heaDownloadFile="{{baseUrl}}/api/disbursement-import/files/{{fileId}}/download"
                [filename]="fileName" class="color-primary flex items-center cursor-pointer">
    <mat-icon class="size-smd" svgIcon="download"></mat-icon>
  </a>`,

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellDownloadComponent implements ICellRendererAngularComp {
  fileId: string;
  fileName = 'Disbursement file';
  baseUrl = environment.baseURL;

  agInit(params: ICellRendererParams): void {
    this.fileId = params.data.fileId;
    this.fileName = params.data.fileName || 'Disbursement file';
  }

  refresh(params: ICellRendererParams): boolean {
    this.fileId = params.data.fileId;
    this.fileName = params.data.fileName || 'Disbursement file';
    return false;
  }

}
