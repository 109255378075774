import {ChangeDetectionStrategy, Component} from '@angular/core';
import {environment} from '@environments/environment';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'hea-ag-cell-demographic-download',
  template: `<a heaDownloadFile="{{baseUrl}}/api/student-demographic-import/files/{{filename}}/download"
                filename="Demographic file"
                class="color-primary flex items-center cursor-pointer">
    <mat-icon class="size-smd" svgIcon="download"></mat-icon>
  </a>`,

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgCellDemographicDownloadComponent implements ICellRendererAngularComp {
  id: string;
  filename: string;
  baseUrl = environment.baseURL;

  agInit(params: ICellRendererParams): void {
    this.id = params.data.id;
    this.filename = params.data.filename;
  }

  refresh(params: ICellRendererParams): boolean {
    this.id = params.data.id;
    this.filename = params.data.filename;
    return false;
  }

}
