import {NgModule} from '@angular/core';
import {StatusModule} from '@shared/components/status';

import {DisbursementStatusComponent} from './disbursement-status.component';

@NgModule({
  declarations: [DisbursementStatusComponent],
  imports: [StatusModule],
  exports: [DisbursementStatusComponent]
})
export class DisbursementStatusModule {
}
