import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AuthService} from '@core/services/auth';
import {AuthTokenType} from '@core/services/auth/auth.types';
import {environment} from '@environments/environment';
import dayjs from 'dayjs';
import jwt_decode from "jwt-decode";
import {catchError, from, Observable, switchMap, throwError} from 'rxjs';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  authService = this._injector.get(AuthService);
  firebaseAuthService = this._injector.get(AngularFireAuth);

  constructor(private _injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addTokens(req)).pipe(
      switchMap((reqWithTokens) => next.handle(reqWithTokens)
        .pipe(
          catchError(error => this.processError(req, next, error))
        )));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    return this.logoutUser(error);
  }

  private processError(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch (error?.status) {
        case 401: {
          return this.handle401Error(req, next, error);
        }
        default:
          return throwError(() => error);
      }
    } else {
      return throwError(() => error);
    }
  }

  private async addTokens(req: HttpRequest<any>): Promise<HttpRequest<any>> {
    const accessToCheck = JSON.parse(localStorage.getItem('user'))?.stsTokenManager?.accessToken;
    const user = JSON.parse(localStorage.getItem('user'));

    if (accessToCheck) {
      const decoded: { exp: number } = jwt_decode(accessToCheck);
      if (decoded?.exp) {
        const fiveMin = 300;
        const expired = dayjs.unix(decoded.exp - fiveMin).isBefore(dayjs());
        if (expired && user) {
          const token = user?.stsTokenManager?.refreshToken;
          if (token) {
            const tokens: any = await this.authService.signInWithToken(token);
            const stsTokenManager = tokens?.user?.multiFactor?.user?.stsTokenManager;
            if (stsTokenManager?.accessToken) {
              const newUser: any = {
                ...user,
                stsTokenManager
              }
              localStorage.setItem('user', JSON.stringify(newUser));
            }
          }
        }
      }
    }

    const access = JSON.parse(localStorage.getItem('user'))?.stsTokenManager?.accessToken;

    const ocpSubscriptionKey = (environment as any)?.ocpSubscriptionKey;
    if (access) {
      req = req.clone({
        setHeaders: {
          [AuthTokenType.access]: `Bearer ${access}`,
        }
      });
    }

    if (ocpSubscriptionKey) {
      req = req.clone({
        setHeaders: {
          [AuthTokenType.ocp]: ocpSubscriptionKey,
        }
      });
    }

    return Promise.resolve(req);
  }

  private logoutUser(error): Observable<never> {
    this.authService.signOut();
    return throwError(() => error);
  }

}
