import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, finalize, Observable, switchMap, tap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {SchoolBalanceTypes} from './school-balance.types';
import {SchoolBalanceApiService} from './school-balance-api.service';

@Injectable({providedIn: 'root'})
export class SchoolBalanceService {
  pendingBalance$: Observable<boolean>;
  errorBalance$: Observable<HttpErrorResponse>;

  private _pendingBalance$ = new BehaviorSubject<boolean>(false);
  private _errorBalance$ = new BehaviorSubject<HttpErrorResponse>(null);
  private _refreshBalance$ = new BehaviorSubject<boolean>(false);

  constructor(private schoolBalanceApiService: SchoolBalanceApiService) {
    this.pendingBalance$ = this._pendingBalance$.asObservable();
    this.errorBalance$ = this._errorBalance$.asObservable();
  }

  getSchoolBalance(): Observable<SchoolBalanceTypes> {
    return combineLatest([this._refreshBalance$])
      .pipe(
        switchMap(() => this.schoolBalanceApiService.getSchoolBalance()
          .pipe(
            tap(() => {
              this._pendingBalance$.next(true);
              this._errorBalance$.next(null);
            }),
            finalize(() => {
              this._pendingBalance$.next(false);
            }),
            catchError((err) => {
              this._errorBalance$.next(err);
              return throwError(() => err);
            })
          ))
      )
  }

  refreshBalance(): void {
    this._refreshBalance$.next(!this._refreshBalance$.value);
  }
}
