import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AgGridModule} from 'ag-grid-angular';

import {DirectivesModule} from '../../directives';
import {MaterialModule} from '../../material';
import {AgGridWrapperComponent} from './ag-grid-wrapper.component';

@NgModule({
  declarations: [
    AgGridWrapperComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    MaterialModule,
    DirectivesModule,
  ],
  exports: [
    AgGridWrapperComponent,
  ]
})
export class AgGridWrapperModule {
}
