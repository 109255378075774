import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[heaBtnPending]'
})
export class BtnPendingDirective {
  @Input('heaBtnPending') get btnPending(): boolean {
    return this._btnPending;
  }

  set btnPending(pending: boolean) {
    this._btnPending = pending;
    if (pending) {
      this.elem.nativeElement.classList.add('loading');
    } else {
      this.elem.nativeElement.classList.remove('loading');
    }
  }

  private _btnPending: boolean;

  constructor(private elem: ElementRef) {
  }

}
