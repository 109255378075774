import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {ErrorMessageService} from '../services/error-message.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addTokens(req)).pipe(
      catchError(error => {
        error = ErrorMessageService.getErrorWithMessage(error);
        return observableThrowError(error);
      }));
  }

  private addTokens(req: HttpRequest<any>): HttpRequest<any> {
    return req;
  }
}
